import React, { useEffect } from "react";
import { IonPage, IonText } from "@ionic/react";
import Lottie from "lottie-react";
import success from "./success.json";
import "./styles.css";

function Thanks() {
  useEffect(() => {
    const timeOut = setTimeout(() => {
      console.log('clear');
      localStorage.clear();
    }, 5000);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);
  return (
    <IonPage>
      <div className="lottie-container">
        <Lottie className="lottie-animation" animationData={success} />
        <IonText color="dark" className="lottie-text">
          <h1>¡Muchas gracias!</h1>
          <h3>Ya puede cerrar la pestaña</h3>
        </IonText>
      </div>
    </IonPage>
  );
}

export default Thanks;
