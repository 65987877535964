import React, { useState } from "react";
import {
  IonContent,
  IonPage,
  IonImg,
  useIonLoading,
  useIonAlert,
  IonText,
  IonButton,
  IonIcon,
} from "@ionic/react";
import {
  Camera,
  CameraDirection,
  CameraResultType,
  CameraSource,
} from "@capacitor/camera";
import {
  base64FromPath,
  resizeImage,
  rotateImage,
} from "./Components/PhotoCropper/utils";

import "./styles.css";
import { useHistory } from "react-router";
import axios from "axios";
import {
  getInitialStep,
  getRandomGesture,
  INITIAL_PHOTOS,
  INITIAL_STEPS,
} from "./helper";
import ButtonSection from "./Components/ButtonSection";
import PhotoButton from "./Components/PhotoButton";
import SteperNumber from "./Components/SteperNumber";
import { reload } from "ionicons/icons";

const Photos = () => {
  const history = useHistory();
  const [present, dismiss] = useIonLoading();
  const [presentAlert] = useIonAlert();
  const [photos, setPhotos] = useState(INITIAL_PHOTOS);
  const [steps, setSteps] = useState(INITIAL_STEPS);
  const [gesture] = useState(getRandomGesture());
  const [currentStep, setCurrentStep] = useState(getInitialStep());

  const resetSteps = (selfie = false) => {
    const _initialSteps = INITIAL_STEPS.map((step) => {
      if (selfie) {
        if (step.id === 4) {
          return { ...step, currentStep: true };
        } else {
          return { ...step, currentStep: false };
        }
      } else {
        if (step.id === 0) {
          return { ...step, currentStep: true };
        } else {
          return { ...step, currentStep: false };
        }
      }
    });
    const _initialPhotos = INITIAL_PHOTOS;
    if (selfie) {
      _initialPhotos[2].base64 = "";
      _initialPhotos[2].webPath = "";
      _initialPhotos[3].base64 = "";
      _initialPhotos[3].webPath = "";
    }
    setPhotos(_initialPhotos);
    setSteps(_initialSteps);
    let _currentStep;
    if (selfie) {
      _currentStep = _initialSteps.filter((step) => step.currentStep)[4];
    } else {
      _currentStep = _initialSteps.filter((step) => step.currentStep)[0];
    }
    setCurrentStep(_currentStep);
  };

  const sendPhotos = async (body) => {
    present({
      message: "Analizando imágenes. Espere por favor...",
    });
    setTimeout(async () => {
      try {
        await axios.post(
          "https://obd.entreconsultas.com/api/OBDMobileEC/",
          body
        );
        if (currentStep.id === 7) {
          return finishSteps();
        }
        nextStep();
      } catch (error) {
        console.log(error);
        if (error.response.status === 423) {
          history.replace("/app/error403");
        }
        if (error.response.status === 400 || error.response.status === 406) {
          resetSteps();
          alert(
            `Hubo un problema al procesar sus fotos. Por favor utilice el DNI correspondiente, y siga detalladamente las recomendaciones de cada paso. Haga clic en 'aceptar' para volver a comenzar. Descripcion del error: ${error.response.data}`
          );
        }
      } finally {
        dismiss();
      }
    }, 2000);
  };

  const goBackStep = () => {
    const _steps = steps.map((step) => step);
    const index = steps.findIndex((step) => step.currentStep);
    _steps[index].currentStep = false;
    _steps[index - 1].currentStep = true;
    setSteps(_steps);
    setCurrentStep(_steps[index - 1]);
  };

  const nextStep = () => {
    const _steps = steps.map((step) => step);
    const index = steps.findIndex((step) => step.currentStep);
    _steps[index].currentStep = false;
    _steps[index + 1].currentStep = true;
    setSteps(_steps);
    setCurrentStep(_steps[index + 1]);
  };

  const goNextStep = async () => {
    if (currentStep.id === 3) {
      const dni_front = await resizeImage(photos[0].base64, 1152, 720);
      const dni_back = await resizeImage(photos[1].base64, 1152, 720);
      const data = {
        uuid: localStorage.getItem("uuid"),
        dni_front,
        dni_back,
      };
      sendPhotos(data);
    } else if (currentStep.id === 7) {
      const dni_front = await resizeImage(photos[0].base64, 1152, 720);
      const selfie = await resizeImage(photos[2].base64, 300, 300);
      const selfie_gesture = await resizeImage(photos[3].base64, 300, 300);
      const data = {
        uuid: localStorage.getItem("uuid"),
        dni_front,
        selfies: [
          { image: selfie, id_selfie: 1, code: 100 },
          { image: selfie_gesture, id_selfie: 2, code: gesture.code },
        ],
      };
      sendPhotos(data);
    } else {
      nextStep();
    }
  };

  const takePhoto = async (name) => {
    try {
      const photo = await Camera.getPhoto({
        quality: 90,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        allowEditing: true,
        direction: name.includes("dni")
          ? CameraDirection.Rear
          : CameraDirection.Front,
        webUseInput: true,
      });
      updateStatePhotos(photo);
    } catch (error) {
      presentAlert({
        header: "ALERTA",
        message:
          "Hubo un problema al abrir la cámara. Por favor verifique los permisos de la misma para poder ser utilizada en el navegador",
        buttons: ["Aceptar"],
        onDidDismiss: null,
      });
    }
  };

  const updateStatePhotos = async (photo) => {
    const index = photos.findIndex((photo) => photo.name === currentStep.name);
    const _photos = photos.filter((photo) => photo);
    const _photo = { ..._photos[index], ...photo };
    _photo.base64 = await base64FromPath(photo.webPath);
    _photos[index] = _photo;
    setPhotos(_photos);
    goNextStep();
  };

  const rotate = async () => {
    const photo = photos.filter((photo) => photo.name === currentStep.name)[0];
    const imagen = await rotateImage(photo.base64);
    const index = photos.findIndex((photo) => photo.name === currentStep.name);
    const _photos = photos.filter((photo) => photo);
    _photos[index] = { ..._photos[index], base64: imagen };
    setPhotos(_photos);
  };

  const handleDeletePhoto = (name) => {
    presentAlert({
      header: "ALERTA",
      message: "¿Está seguro que desea volver a capturar la foto?",
      buttons: ["Cancelar", { text: "Si", handler: () => deletePhoto(name) }],
      onDidDismiss: null,
    });
  };

  const deletePhoto = (name) => {
    const index = photos.findIndex((photo) => photo.name === name);
    const _photos = photos.filter((photo) => photo);
    _photos[index] = { ..._photos[index], base64: "", webPath: "" };
    setPhotos(_photos);
    goBackStep();
  };

  const finishSteps = () => {
    history.replace("/app/thanks");
  };

  return (
    <IonPage>
      <IonContent>
        <div className="step-body">
          <SteperNumber stepNumber={currentStep.stepNumber} />
          {currentStep && (
            <div className="step-body-center">
              {currentStep.type === "guide" ? (
                <div className="step-body-text">
                  <div className="step-guide-text">
                    <IonText color="dark">
                      <span style={{ fontWeight: 600 }}>
                        {currentStep.content.title.line1}
                      </span>
                      <span> - </span>
                      <span style={{ fontWeight: 900 }}>
                        {currentStep.id === 6
                          ? gesture.label.toUpperCase()
                          : currentStep.content.title.line2}
                      </span>
                    </IonText>
                    <IonText color="dark">
                      <p style={{ fontWeight: 700, width: 250, margin: '15px auto' }}>
                        {currentStep.content.text}
                      </p>
                    </IonText>
                  </div>
                  <IonImg
                    className="ion-no-padding"
                    src={currentStep.id === 6 ? gesture.img : currentStep.image}
                    style={{ width: "275px" }}
                  />
                </div>
              ) : (
                <div className="photo-container">
                  <div>
                    <IonImg
                      src={
                        photos.filter(
                          (photo) => photo.name === currentStep.name
                        )[0].base64
                      }
                      style={{
                        maxWidth: "90%",
                        height: "300px",
                        margin: "15px auto 5px auto",
                      }}
                    />
                    <IonButton
                      color="primary"
                      fill="clear"
                      onClick={() => handleDeletePhoto(currentStep.name)}
                    >
                      <IonIcon slot="start" icon={reload} />
                      <span>Volver a capturar</span>
                    </IonButton>
                  </div>
                  <ButtonSection
                    currentStep={currentStep}
                    handleDeletePhoto={handleDeletePhoto}
                    rotate={rotate}
                    goNextStep={goNextStep}
                  />
                </div>
              )}
            </div>
          )}
          {currentStep.type === "guide" && (
            <PhotoButton
              photos={photos}
              currentStep={currentStep}
              goNextStep={goNextStep}
              takePhoto={takePhoto}
            />
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Photos;
