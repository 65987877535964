import React from "react";
import { Redirect } from "react-router-dom";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
/* Theme variables */
import "./theme/variables.css";
/* Global CSS */
import "./global.css";
/* Pages */
import Welcome from "./pages/Welcome/Welcome";
import Examples from "./pages/Examples/Examples";
import Error404 from "./pages/Error/Error404/Error404";
import Error403 from "./pages/Error/Error403/Error403";
import Photos from "./pages/Photos/Photos";
import Thanks from "./pages/Thanks/Thanks";
import ErrorPlatform from "./pages/Error/ErrorPlatform/ErrorPlatform";
import Guide from "./pages/Guide/Guide";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";

const App = () => (
  <IonApp className="app-container">
    <IonReactRouter>
      <IonRouterOutlet>
        <PrivateRoute path="/app/guide" component={Guide} exact={true} />
        <PrivateRoute path="/app/photos" component={Photos} exact={true} />
        <PrivateRoute path="/app/examples" component={Examples} exact={true} />
        <PrivateRoute path="/app/thanks" component={Thanks} exact={true} />
        <PublicRoute path="/app/error404" component={Error404} exact={true} />
        <PublicRoute path="/app/error403" component={Error403} exact={true} />
        <PublicRoute
          path="/app/errorPlatform"
          component={ErrorPlatform}
          exact={true}
        />
        <PublicRoute path="/start/:uuid?" component={Welcome} exact={true} />
        <PublicRoute path="/" component={Welcome} exact={true} />
        <PublicRoute render={() => <Redirect to="/app/error404" />} />
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
