export async function base64FromPath(path) {
  const response = await fetch(path);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject("method did not return a string");
      }
    };
    reader.readAsDataURL(blob);
  });
}

export const resizeImage = (base64Str, maxWidth = 400, maxHeight = 350) => {
  return new Promise((resolve) => {
    let img = new Image();
    img.src = base64Str;
    img.onload = () => {
      let canvas = document.createElement("canvas");
      const MAX_WIDTH = maxWidth;
      const MAX_HEIGHT = maxHeight;
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL());
    };
  });
};

// export const rotateImage = (base64, side, cb) => {
//   let img = new Image();
//   img.src = base64;

//   // Create a canvas object.
//   let canvas = document.createElement("canvas");

//   // Wait till the image is loaded.
//   img.onload = function () {
//     rotateImage();
//     saveImage(img.src.replace(/^.*[\\\/]/, ""));
//   };

//   let rotateImage = () => {
//     // Create canvas context.
//     let ctx = canvas.getContext("2d");

//     // Assign width and height.
//     canvas.width = img.width;
//     canvas.height = img.height;

//     ctx.translate(canvas.width / 2, canvas.height / 2);

//     // Rotate the image and draw it on the canvas.
//     // (I am not showing the canvas on the webpage.
//     ctx.rotate(Math.PI);
//     ctx.drawImage(img, -img.width / 2, -img.height / 2);
//   };

//   let saveImage = (img_name) => {
//     const imagee = canvas.toDataURL("image/png");
//     cb(imagee);
//   };
// };

export const rotateImage = (image) => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.src = image;
    canvas.height = img.width;
    canvas.width = img.height;

    img.onload = function () {
      ctx.rotate((90 * Math.PI) / 180);
      ctx.translate(0, -canvas.width);
      ctx.drawImage(img, 0, 0);
      const _imagen = canvas.toDataURL("image/png", 100);
      resolve(_imagen);
    };
  });
};

export const getWidth = () => {
  return window.innerWidth < 600 ? window.innerWidth : 600;
};
export const getHeight = (imageSize) => {
  const aspect = imageSize.w / imageSize.h;
  return window.innerWidth / aspect < 600 ? window.innerWidth / aspect : 600;
};
